<template>
    <div class="page">
        <div class="title">审批模板库</div>

        <el-divider></el-divider>

        <!-- filter -->
        <div class="filter_box">
            <div class="filter_search">
                <!-- input -->
                <el-input
                    class="filter_search_input"
                    placeholder="请输入关键词"
                    v-model="search.keyword"
                    clearable
                >
                </el-input>
                <div class="custom_button no_select" @click="toSearch">
                    搜索
                </div>
            </div>
            <div class="under_filter">
                模板类型： 人事审批 考勤审批 薪酬审批 行政审批 其他
            </div>
        </div>

        <!-- table -->
        <el-table
            id="table"
            :data="tableData"
            :header-cell-style="tableHeaderStyle"
            :cell-style="tableCellStyle"
            style="width: 100%;"
        >
            <el-table-column
                prop="title"
                label="审批模板名称"
            ></el-table-column>
            <el-table-column prop="desc" label="模板说明"></el-table-column>
            <el-table-column label="操作" min-width="180" fixed="right">
                <template v-slot:default="scope">
                    <div class="operations">
                        <div @click="toUse(scope.row.id)">
                            使用模板
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            layout="prev, pager, next"
            :total="page.total"
            :page-size="page.size"
            :current-page.sync="page.page"
            @current-change="loadData"
        >
        </el-pagination>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            search: {
                keyword: "",
                date: "",
            },
            tableData: [],
            // 分页参数
            page: {
                size: 15,
                page: 1,
                total: 0,
            },
            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    created() {
        this.loadData = this.loadDataFactory();
        this.loadData();
    },
    methods: {
        ...mapActions("attendance", ["getApprovalTemplateList"]),

        loadDataFactory(keyword = null) {
            let params = {};
            if (keyword) {
                params.keyword = keyword;
            }
            return () => {
                params.page = this.page.page;
                // let loading = this.$loading();
                this.getApprovalTemplateList(params)
                    .then((res) => {
                        // loading.close();
                        console.log(res);
                        this.tableData = res.data.data.list;
                        this.page.total = res.data.data.total;
                        this.$nextTick(() => {
                            // loading.close();
                        });
                    })
                    .catch((e) => {
                        this.$message.error(e.msg);
                        // loading.close();
                    });
            };
        },

        /**
         * 搜索
         */
        toSearch() {
            this.page.page = 1;
            // 生产带有搜索参数的函数
            this.loadData = this.loadDataFactory(this.search.keyword);
            this.loadData();
        },

        toUse(id) {
            this.$router.push({
                // name: "ApprovalFormsEditor",
                name: "ApprovalFormsEditorId",
                params: { tid: id },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

.filter_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    position: relative;

    .el-date-editor {
        height: 36px;
        margin-right: 10px;

        ::v-deep .el-input__inner {
            height: 36px;
        }
    }

    .filter_search {
        display: flex;
        align-items: center;

        .filter_search_input {
            width: 350px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }
        }
    }

    .filter_btns {
        display: flex;
        align-items: center;

        > div:not(:first-child) {
            margin-left: 20px;
        }
    }

    .under_filter {
        font-size: 12px;
        color: #c0c4cc;
        position: absolute;
        left: 0;
        bottom: -30px;
    }
    margin-bottom: 70px;
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}
</style>
